import { parseDecimalDk, Question, DocumentDrafterQuestionnaire, QuestionnaireWrapper, RequestForOfferQuestionnaireValidator, sum } from '@dims/components';
import { DateTime } from 'luxon';
import deliveryAgreementIds from '@/models/DeliveryAgreementIds';
import { Tender5086 } from '@/models/Tender';

export default class RequestQuestionnaireValidator implements RequestForOfferQuestionnaireValidator {
  questionnaire;

  constructor(readonly tender: Tender5086, questionnaire: DocumentDrafterQuestionnaire) {
    this.questionnaire = new QuestionnaireWrapper(
      this.tender.agreementConfiguration.questionnaires.requestForOffer,
      questionnaire,
    );
  }
  get incomplete() {
    return !this.questionnaire.content.complete;
  }

  minValue(question: Question): number | undefined {
    if (question.questionId === deliveryAgreementIds.idAPV) {
      return 51;
    }
    if (question.questionId === deliveryAgreementIds.idCost) {
      return 1;
    }
    if (question.questionId === deliveryAgreementIds.idPriceSpanPct) {
      return 1;
    }
    if (question.questionId === deliveryAgreementIds.idCarsBoughtInitially) {
      return 1;
    }
    if (question.questionId === deliveryAgreementIds.idOptionalCarsAfterAgreement) {
      return 1;
    }
    if (question.questionId === deliveryAgreementIds.idDeadlineQuestions) {
      return 7;
    }
    if (question.questionId === deliveryAgreementIds.idUserTestParticipants) {
      return 1;
    }
    if (question.questionId === deliveryAgreementIds.idDeliveryDaysAfterContract) {
      return 1;
    }
    if (question.dataFieldType === 'Number') {
      return 0;
    }
    return undefined;
  }

  maxValue(question: Question): number | undefined {
    if (question.questionId === deliveryAgreementIds.idAPV) {
      return 99;
    }
    if (question.questionId === deliveryAgreementIds.idCost) {
      return 49;
    }
    if (question.questionId === deliveryAgreementIds.idPriceSpanPct) {
      return 100;
    }
    if (question.questionId === deliveryAgreementIds.idOptionalCarsAfterAgreement) {
      // As we can't rely on that this.tender is updated we need to look referenced value up from questionnaire
      const field = this.questionnaire.content.questions.find((q) => q.questionId === deliveryAgreementIds.idCarsBoughtInitially);
      if (field !== undefined) {
        const initialCars = +(field.answers?.first().value ?? 1);
        return initialCars;
      }
      return this.tender.data.carsBoughtInitially ?? 1;
    }
    return undefined;
  }

  minDate(question: Question): string | undefined {
    if (question.questionId === deliveryAgreementIds.idDeliveryDateTestVehicle) {
      return this.tender.deadlineForTender ?? undefined;
    }
    if (question.dataFieldType === 'Date') {
      return DateTime.local().toISODate();
    }
    return undefined;
  }

  get isBPQ() {
    const question = this.questionnaire.content.questions
      .find((q) => q.questionId === deliveryAgreementIds.idAwardingCriteria);
    return question?.answers
      ?.find((a) => a.answerId === deliveryAgreementIds.idAwardingCriteriaAnswer_BPQ)
      ?.selected ?? false;
  }

  get validateIsSubCriteriaSum100Percent() {
    return this.sumOfSubCriteria === 100;
  }

  get isSubcriteriaValid() {
    return !this.isBPQ || this.validateIsSubCriteriaSum100Percent;
  }

  get isCustomBpqRatioPercentageValid() {
    if (this.tender.awardCriteriaType === 'TCO') return true;

    const bpqRatio = this.questionnaire.content.questions.find((q) => q.questionId === deliveryAgreementIds.idBpqRatioPercentage);
    const selectedAnswer = bpqRatio?.answers?.find((answer) => answer.selected && answer.text === 'Anden procentdel');
    if (!selectedAnswer) return true;

    return this.sumOfCustomBpqRatioPercentage === 100;
  }

  get sumOfSubCriteria(): number {
    return sum(this.currentSubCriteria.map((q) => parseDecimalDk(q.answers?.[0]?.value ?? '0') ?? 0));
  }

  get sumOfCustomBpqRatioPercentage() {
    const validationQuestions = this.questionnaire.content.questions.filter((q) => q.questionId === deliveryAgreementIds.idCost || q.questionId === deliveryAgreementIds.idAPV);
    const values = validationQuestions.map((q) => (q.answers?.[0] && q.answers[0].value !== '' ? parseFloat(q.answers[0].value) : 0));
    return sum(values);
  }

  /** Gets an array of IDs for the questions corresponding to the currently
   * checked quality subcriteria */
  get currentSubCriteria() {
    const selectedAnswerIds = this.questionnaire.content.questions
      .find((q) => q.questionId === deliveryAgreementIds.idQualityEvaluation)?.answers;

    if (!selectedAnswerIds) {
      return [];
    }

    const questionIds: string[] = selectedAnswerIds.map((a) => {
      switch (a.answerId) {
        case deliveryAgreementIds.idQualityEvaluationAnswer_DrivingCharacteristics:
          return deliveryAgreementIds.idDrivingCharacteristics;
        case deliveryAgreementIds.idQualityEvaluationAnswer_DriverSeatAdjustment:
          return deliveryAgreementIds.idDriverSeatAdjustment;
        case deliveryAgreementIds.idQualityEvaluationAnswer_EntryExitConditions:
          return deliveryAgreementIds.idEntryExitConditions;
        case deliveryAgreementIds.idQualityEvaluationAnswer_View:
          return deliveryAgreementIds.idView;
        case deliveryAgreementIds.idQualityEvaluationAnswer_CabinNoise:
          return deliveryAgreementIds.idCabinNoise;
        case deliveryAgreementIds.idQualityEvaluationAnswer_Instrumentation:
          return deliveryAgreementIds.idInstrumentation;
        case deliveryAgreementIds.idQualityEvaluationAnswer_Comfort:
          return deliveryAgreementIds.idComfort;
        default:
          throw new Error('ID not found');
      }
    });

    return this.questionnaire.content.questions.filter(
      (q) => questionIds.includes(q.questionId),
    );
  }

  get subCriteriaValidation() {
    if (this.isSubcriteriaValid) {
      return null;
    }
    const criteriaSum = this.sumOfSubCriteria;

    const missingExplanation = this.currentSubCriteria.some(
      (q) => !q.answers || q.answers.length === 0 || q.answers[0]?.value === '',
    ) ? 'Nogle værdier mangler eller er ikke tal' : '';

    const error = Number.isNaN(criteriaSum) ? missingExplanation : `Summen er ${criteriaSum}% ${missingExplanation}`;
    return { message: `Summen af delkriterierne skal være 100% (${error})` };
  }

  get customBpqRatioPercentageValidation() {
    if (this.isCustomBpqRatioPercentageValid) {
      return null;
    }
    const percentageSum = this.sumOfCustomBpqRatioPercentage;
    return {
      message: `Summen af kriterierne skal være 100% (Summen er ${percentageSum})`,
      question: this.questionnaire.content.questions.find((q) => q.questionId === deliveryAgreementIds.idAPV),
    };
  }
}
